<template>
  <div id="printableArea" class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">
      Informações do Push Batch
      <span v-if="this.$route.params.id">#{{ this.$route.params.id }}</span>
    </h1>

    <div v-if="loading_validate !== true">
      <div class="row">
        <message-card
          :nameCard="'Enrollments Initial'"
          :amount="countEnrollments.initial"
          :color="'info'"
        ></message-card>

        <message-card
          :nameCard="'Enrollments Active'"
          :amount="countEnrollments.active"
          :color="'info'"
        ></message-card>

        <message-card
          :nameCard="'Enrollments Completed'"
          :amount="countEnrollments.completed"
          :color="'primary'"
        ></message-card>

        <message-card
          :nameCard="'Enrollments Inactive'"
          :amount="countEnrollments.inactive"
          :color="'danger'"
        ></message-card>

        <message-card
          :nameCard="'Enrollments On Hold'"
          :amount="countEnrollments.onHold"
          :color="'danger'"
        ></message-card>


        <message-card
          :nameCard="'Enrollments No Activity'"
          :amount="countEnrollments.noActivity"
          :color="'danger'"
        ></message-card>

        <message-card
          :nameCard="'Enrollments Missing Token'"
          :amount="countEnrollments.missingToken"
          :color="'danger'"
        ></message-card>


        <message-card
          :nameCard="'Conversão de Enrollments'"
          :amount="parcial + '% ' + 'de ' + number_enroll"
          :color="'success'"
        ></message-card>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-3 mt-3">
        <button class="btn bg-gradient-warning fw-bold" style="color: #fff" data-bs-toggle="modal" data-bs-target="#validarModal">Validar Push Batch</button>
        <button class="btn bg-gradient-primary fw-bold" style="color:azure"  @click="printCurrentPage">Gerar Relatório</button>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="validarModal" tabindex="-1" aria-labelledby="#validarModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="validarModalLabel">Validar Push Batch - {{ this.validateStatus }}</h1>
            </div>

            <div class="modal-body">
              <div class="mb-3">
                <label for="comment" class="form-label">Comentário</label>
                <textarea class="form-control" id="comment" v-model="commentValidate" rows="3" placeholder="Digite o motivo da aprovação ou rejeição"></textarea>              
              </div>
              
              <div class="mb-3">
                <label for="start_date_time" class="form-label">Data desejada de disparo</label>
                <input type="datetime-local" class="form-control" id="start_date_time" v-model="startDateValidate" placeholder="00/00/0000 00:00">
              </div>            
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-success" @click="aprovePushBatch" data-bs-dismiss="modal">Aprovar</button>
              <button type="button" class="btn btn-danger" @click="rejectPushBatch" data-bs-dismiss="modal">Rejeitar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 v-if="courseName" class="m-0 font-weight-bold text-primary">
            CURSO: #{{ courseId }} - {{ courseName }}
          </h6>
          <br>
          <button @click="downloadCSV" type="button" class="btn btn-success bg-gradient-info"><i class="bi bi-filetype-csv"></i></button>
          <br>
          <br>
          <div class="progress" role="progressbar" aria-label="Success example" :aria-valuenow="progress_csv" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar bg-info" :style="{ width: progress_csv + '%' }">{{ progress_csv }}%</div>
          </div>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
              selectedClass="table-info"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Nome do Aluno</th>
                  <th>Número de Telefone</th>
                  <th>Data de Criação</th>
                  <th>Data de Modificação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in allEnrollments" v-bind:key="data.id">
                  <td>#{{ data.id }}</td>
                  <td>{{ data.status }}</td>
                  <td>{{ data.student_name }}</td>
                  <td>{{ data.student_phone_number }}</td>
                  <td>{{ formatDate(data.created) }}</td>
                  <td>{{ formatDate(data.modified) }}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="!this.allEnrollments" class="container-loading">
              <i class="bi bi-hourglass-split">Carregando...</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center align-items-center gap-3 mt-3">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../http";
import Loader from "../shared/painel/Loader.vue";
import MessageCard from "../shared/message-cards/MessageCard.vue";

export default {
  components: {
    "message-card": MessageCard,
    Loader,
  },

  data() {
    return {
      allData: null,
      allEnrollments: null,
      courseId: null,
      courseName: null,
      countEnrollments: {
        initial: 0,
        active: 0,
        completed: 0,
        inactive: 0,
        missingToken: 0,
        noActivity: 0,
        onHold: 0,
      },
      countMessagesStatus: {
        queued: 0,
        sent: 0,
        resent: 0,
        failed: 0,
        delivered: 0,
        read: 0
      },      
      parcial: 0,
      progress_csv: 0,
      number_enroll: 0,

      loading_validate: false,
      commentValidate: null,
      startDateValidate: null,
      validateStatus: null,
    };
  },

  mounted() {
    this.getAllEnrollments();
  },

  methods: {
    downloadCSV() {
      let csvContent = "ID,Status,Nome do Aluno,Número de Telefone,Data de Criação,Data de Modificação\n";
      let processed_enrollments = 0
      if (this.allEnrollments != null) {
        this.allEnrollments.forEach(item => {
          csvContent +=
            `${item.id},` +
            `${item.status},` +
            `${item.student_name},` +
            `${item.student_phone_number},` +
            `${this.formatDate(item.created)},` +
            `${this.formatDate(item.modified)}\n`;

          processed_enrollments += 1;
          this.progress_csv = (processed_enrollments / this.allEnrollments.length) * 100;
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setTimeout(() => {
        // console.log("5 segundos se passaram");
        this.progress_csv = 0;
      }, 2500);
        
    },

    printCurrentPage() {
      var elementId = 'printableArea'
      var printContent = document.getElementById(elementId).innerHTML;
      var originalPageStyle = document.getElementsByTagName('style');
      var newWindow = window.open('', '_blank', 'width=800,height=600');
      newWindow.document.open();
      newWindow.document.write('<html><head><title>Print</title>');
      // Copia os estilos para a nova janela
      for (var i = 0; i < originalPageStyle.length; i++) {
          newWindow.document.write('<style>' + originalPageStyle[i].innerHTML + '</style>');
      }
      newWindow.document.write('</head><body>');
      newWindow.document.write(printContent);
      newWindow.document.write('</body></html>');
      newWindow.document.close();
      newWindow.print();
    },

    getAllEnrollments() {
      if (this.$route.params.id != null) {
        var payload = { 
          push_batch_id: this.$route.params.id 
        };

        http.post("painel/atend_comercial/report_by_push_converting_enrollments/", payload)
        .then((response) => {
          this.allData = response.data;
        })
        .finally(() => {
          this.allEnrollments = this.allData.enrollments;
          this.courseId = this.allData.course_id;
          this.courseName = this.allData.course_name;
          this.commentValidate = this.allData.info_aprove_push_batch.coment;
          this.startDateValidate = this.allData.info_aprove_push_batch.date_start_send;
          this.validateStatus = this.allData.info_aprove_push_batch.aproved;

          if (this.allEnrollments) {
            this.number_enroll = this.allEnrollments.length;
          } else {
            this.number_enroll = 0;
          }

          // contagem dos status das messages
          this.countMessagesStatus.queued = this.allData.messages_status.queued;
          this.countMessagesStatus.sent = this.allData.messages_status.sent;
          this.countMessagesStatus.resent = this.allData.messages_status.resent;
          this.countMessagesStatus.failed = this.allData.messages_status.failed;
          this.countMessagesStatus.delivered = this.allData.messages_status.delivered;
          this.countMessagesStatus.read = this.allData.messages_status.read;

          // contagem dos status dos enrollments
          this.countEnrollments.initial = this.allData.count_enrollments.initial;
          this.countEnrollments.active = this.allData.count_enrollments.active;
          this.countEnrollments.completed = this.allData.count_enrollments.completed;
          this.countEnrollments.inactive = this.allData.count_enrollments.inactive;
          this.countEnrollments.missingToken = this.allData.count_enrollments.missing_token;
          this.countEnrollments.noActivity = this.allData.count_enrollments.no_activity;
          this.countEnrollments.onHold = this.allData.count_enrollments.on_hold;

          let activecompleted = parseInt(this.countEnrollments.active) + parseInt(this.countEnrollments.completed);
          let allstatus = parseInt(this.countEnrollments.initial) + parseInt(this.countEnrollments.active) + parseInt(this.countEnrollments.completed) + parseInt(this.countEnrollments.inactive) + parseInt(this.countEnrollments.missingToken) + parseInt(this.countEnrollments.noActivity) + parseInt(this.countEnrollments.onHold);

          this.parcial = ((activecompleted/allstatus) * 100).toFixed(2);
        });          
      }        
    },

    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;      
    },
    
    aprovePushBatch() {
      this.loading_validate = true;
      this.validateStatus = 'aproved';

      this.validatePushBatch(this.validateStatus);
    },

    rejectPushBatch() {
      this.loading_validate = true;
      this.validateStatus = 'rejected';

      this.validatePushBatch(this.validateStatus);
    },

    validatePushBatch(status) {
      var payload = { 
        push_batch_id: this.$route.params.id,
        aproved: status.toString(),
        coment: this.commentValidate == null ? null : this.commentValidate.toString(),
        start_date_time: this.startDateValidate == null ? null : this.startDateValidate,
      };

      console.log(payload);

      http.post("painel/atend_comercial/scheduled-push-batch/", payload)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading_validate = false;
        this.commentValidate = '';
        this.startDateValidate = '';
        this.getAllEnrollments();
      });
    }
  },
  
};
</script>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
