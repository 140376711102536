<template>
<div class="container-fuild">
    <message-cards :total_message="this.total_message" :heig_priority="this.heig_priority" :medium_priority="this.medium_priority" :enrolments_talk_tutor="this.enrolments_talk_tutor"></message-cards>
    <div class="card shadow mb-4">

        <div class="card-header py-3">
            <div class="row align-items-start">
                <div class="col">
                    <h6 class="m-0 font-weight-bold text-primary">Curso:</h6>
                    <select @change="handleChange" class="form-select">
                        <option :value="null" selected>SELECIONE O CURSO</option>
                        <option :value="course.course_id" v-for="course in courses" v-bind:key="course.course_id">
                            Projeto: {{course.exercise_token}} - {{ course.name }} - (prioridades: {{ course.statistics.high_priority + course.statistics.medium_priority }})
                        </option>
                    </select>
                </div>
                <div v-if="this.is_admin" class="col">
                    <h6 class="m-0 font-weight-bold text-primary">Tutor:</h6>
                    <select @change="handleChangeTutor" class="form-select">
                        <option :value="null" selected>SELECIONE O TUTOR</option>

                        <option :value="tutor.id" v-for="tutor of this.tutors" v-bind:key="tutor.id">
                            {{ tutor.email }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div id="chat-bar" v-if="this.enrolment_id">
            <div class="content-father-flex">
                <div class="content-child-flex">
                    <div>
                        <div class="form-check" v-on:change="handleCheckboxChange()">
                            <input v-if="talk_tutor == false" class="form-check-input" type="checkbox" id="talktutor" />
                            <input v-if="talk_tutor == true" class="form-check-input" type="checkbox" id="talktutor" checked />
                            <label class="form-check-label" for="flexCheckDefault">
                                Conversar com Aluno &nbsp;
                            </label>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layout-wtf" viewBox="0 0 16 16">
                                <path d="M5 1v8H1V1h4zM1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm13 2v5H9V2h5zM9 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9zM5 13v2H3v-2h2zm-2-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3zm12-1v2H9v-2h6zm-6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9z" />
                            </svg>
                        </div>
                    </div>

                    <div class="btn btn-outline-secondary rounded-pill" data-toggle="modal" data-target="#modalLessons" type="button" v-if="this.is_admin" v-on:click="optionTutor">
                        Opções &nbsp;<ion-icon name="cog"></ion-icon>
                    </div>

                    <div>
                        <div class="form-check fs-5">
                            <span class="badge text-bg-secondary"># {{ this.enrolment_id }}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <button @click="changePriorityOfAllMessages" type="button" class="btn btn-danger rounded-pill">
                        Limpar prioridades
                    </button>
                </div>
            </div>

            <!-- Lessons Modal-->
            <div class="modal fade" id="modalLessons" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-fullscreen modal-opcoes-tutores">
                    <div class="modal-content">
                        <div class="modal-header bg-gradient-primary header-modal-options">
                            <h5 class="modal-title text-light" id="exampleModalLabel">
                                Opções para Tutores
                            </h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i class="bi bi-x-circle new-icons text-light"></i></span>
                            </button>
                        </div>
                        <div class="modal-body" v-if="this.loader_options == true">
                            <Loader></Loader>
                        </div>
                        <div class="modal-body" v-if="this.loader_options == false">
                            <alerts-default 
                                            :message_alert="message_alert" 
                                            :type_alert="type_alert" 
                                            :name_first_button="name_first_button" 
                                            :name_second_button="name_second_button" 
                                            :view_status="view_status" 
                                            :type_confimation="local_type_confimation"
                                            @statusUpdate="statusUpdate"
                                            @cloaseAlert="cloaseAlert">

                            </alerts-default>
                            
                            <!--exercise_external_token-->
                            <div class="content-father-flex">
                                <div v-if="exercise_external_token">
                                    <span class="badge text-bg-primary">
                                        Exercise external token: {{ this.exercise_external_token }}
                                    </span>
                                </div>

                                <div class="content-child-flex" style="gap: 20px;">
                                    <!--button ResendCertificate-->
                                    <div>
                                        <button type="button" class="btn btn-info fw-bold" @click="this.ResendCertificate">
                                            Reenviar certificado &nbsp;
                                            <i class="bi bi-arrow-repeat" aria-hidden="true"></i>
                                        </button>

     
                                    </div>
                                    
                                    <div>
                                        <button type="button" class="btn btn-warning text-light fw-bold" data-toggle="modal" data-target="#modalEditStudent1" aria-expanded="false">
                                            Editar o Nome do Estudante &nbsp;
                                            <i class="bi bi-pencil-fill"></i>
                                        </button>
                                    </div>
                                    <!-- Modal Edit Student -->
                                    <div class="modal fade" id="modalEditStudent1" tabindex="-1" role="dialog" aria-labelledby="EditStudent" aria-hidden="true">
                                        <div class="modal-dialog fullscrem" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title">
                                                        Coloque o novo nome
                                                    </h5>
                                                </div>
                                                <div class="modal-body">
                                                    <form>
                                                        <div class="form-group">
                                                            <label for="recipient-name" class="col-form-label">Editar novo nome:</label>
                                                            <input type="text" v-model="name_student" class="form-control" id="recipient-name" />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" aria-label="Close" @click="this.closeEditStudentModal">
                                                        Cancelar
                                                    </button>
                                                    <button type="button" class="btn btn-primary" @click="this.editStudent">
                                                        Salvar
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-if="this.catch_alert.type == 'success'">
                                                <b-alert variant="success" show>
                                                    {{ this.catch_alert.message }}
                                                </b-alert>
                                            </div>
                                            <div v-if="this.catch_alert.type == 'error'">
                                                <b-alert variant="danger" show>
                                                    {{ this.catch_alert.message }}
                                                </b-alert>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr class="border border-primary border-3 opacity-75">

                            <ul class="nav nav-tabs" id="subNavigation" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="lesson-tab" data-bs-toggle="tab" data-bs-target="#lesson-tab-pane" type="button" role="tab" aria-controls="lesson-tab-pane" aria-selected="true">Lessons Progress</button>
                                </li>

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="message-tab" data-bs-toggle="tab" data-bs-target="#message-tab-pane" type="button" role="tab" aria-controls="message-tab-pane" aria-selected="false">Message Templates</button>
                                </li>
                            </ul>

                            <div class="tab-content" id="subNavigationContent">
                                <div class="tab-pane fade show active p-3" id="lesson-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                    <div class="table-responsive-sm table-max-height-scroll">
                                        <table class="table table-sm table-hover table-bordered border-dark rounded-circle">
                                            <thead class="table-primary">
                                                <tr>
                                                    <th scope="col">@Enrolment ID</th>
                                                    <th scope="col">#ID Lesson Progres</th>
                                                    <th scope="col">Name Lesson</th>
                                                    <th scope="col">Lesson Progress Status</th>
                                                    <th scope="col">Status Enrolment</th>
                                                    <th scope="col">Course Name</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="erolment_lesson in this.enrolments_lesson" v-bind:key="erolment_lesson.id">
                                                    <td>@{{ erolment_lesson.enrolment_id }}</td>
                                                    <th>{{ erolment_lesson.id }}</th>
                                                    <td>{{ erolment_lesson.lesson_name }}</td>
                                                    <td>{{ erolment_lesson.status }}</td>
                                                    <td>{{ erolment_lesson.status_enrolment }}</td>
                                                    <td>{{ erolment_lesson.course_name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>                                        
                                    </div>

                                    <div class="w-100 d-flex flex-row-reverse">
                                        <button class="btn btn-danger w-100" id="btnLssonProgress" v-on:click="openAlertLessonProgress()">
                                            Deletar última <i>LessonProgress</i>&nbsp;<i class="bi bi-exclamation-circle-fill text-warning"></i>
                                        </button>    
                                    </div>
                                </div>

                                <div class="tab-pane fade p-3" id="message-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                    <div class="table-responsive-sm">
                                        <table class="table table-sm table-hover table-bordered border-dark rounded-circle">
                                            <thead class="table-info">
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Name Template</th>
                                                    <th scope="col">Button</th>
                                                    <th scope="col">Content Text</th>
                                                    <th scope="col">Content URL</th>
                                                    <th scope="col">Send Message</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="messages_template in this.messages_templates" v-bind:key="messages_template.id">
                                                    <th class="align-middle">
                                                        {{ messages_template.id }}
                                                    </th>
                                                    <td>{{ messages_template.name }}</td>
                                                    <td class="align-middle">
                                                        <span v-if="messages_template.button == true">
                                                            <i class="bi bi-check-circle text-success"></i>
                                                        </span>
                                                        <span v-if="messages_template.button == false">
                                                            <i class="bi bi-x-circle text-danger fs-4"></i>
                                                        </span>
                                                    </td>
                                                    <td>{{ messages_template.content_text }}</td>
                                                    <td>{{ messages_template.content_url }}</td>
                                                    <td class="center-items-table">
                                                        <button class="btn btn-circle btn-sm" id="btnLssonProgress" @click="init_send_message_template(messages_template.id)">
                                                            <i class="bi bi-chat-dots-fill text-success fs-4"></i>
                                                        </button>
                                                    </td>
                                                </tr>

                                                {{this.template_id}}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="row">
                                <div class="col">
                                    <b-alert :show="this.dismissCountDown_message_template" dismissible variant="success" @dismiss-count-down="
                          this.countDownChanged_message_template
                        ">
                                            <button class="close" type="button" @click="this.cancel_exclude_send_message_template">
                                                <span aria-hidden="true">Cancelar ×</span>
                                            </button>
                                            <p>
                                                Sending Message in
                                                {{ this.dismissCountDown_message_template }}
                                                seconds...
                                            </p>
                                            <b-progress variant="success" :max="this.dismissSecs_message_template" :value="this.dismissCountDown_message_template" height="4px"></b-progress>
                                    </b-alert>                                        
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-chat">
            
            <div class="row">
                <section class="discussions">
                        
                        <div class="discussion search">
                            <div class="searchbar">
                                <input v-model="txtInputFilterStudent" @keyup.enter="filterStudent" type="text" class="text-input-filter-student" placeholder="Buscar pelo Id ou Nome" :disabled="this.course_id != null ? false : true" />
                                <div v-on:click="filterStudent" class="icon h5 icon-filter-student" :style="
                        this.course_id != null
                        ? 'cursor: pointer'
                        : 'cursor: not-allowed'
                    "></div>
                            </div>
                        </div>
                        
                    
                    
                    <div v-for="enrolment of enrolmentsWithFilter" v-bind:key="enrolment.id">
                        <div v-on:click="
                  clickEnrolment(enrolment.id, enrolment.message_window_open)
                " :class="[
                  'discussion',
                  enrolment.id == getEnrolmentId() ? 'message-active' : '',
                ]">
                            <div class="photo" style="background-image: url(https://icons.iconarchive.com/icons/custom-icon-design/pretty-office-8/64/User-blue-icon.png);
                  ">
                                <div class="online"></div>
                            </div>
                            <div class="desc-contact">
                                <p class="name">{{ enrolment.student_id }}</p>
                                <p v-if="is_admin" class="name">
                                    {{ enrolment.student_name }}
                                </p>
                                <p v-if="is_admin" class="message">
                                    {{ enrolment.student_phone_number }}
                                </p>
                            </div>

                            <div class="priority-number mr-2">
                                <i v-if="enrolment.message_window_open" class="bi bi-door-open"  style="color:dodgerblue"></i>
                                
                                <i v-if="enrolment.talk_to_tutor"  class="bi bi-exclamation-circle-fill" style="color:olivedrab"></i>
                                <span style="margin-left: 3%" v-if="enrolment.count_high_priority > 0" class="count-high-priority">
                                    {{ enrolment.count_high_priority }}
                                </span>
                            </div>
                        </div>
                    </div>
                
                </section>

                <section class="chat">
                    <div id="messageChat" class="messages-chat">
                        <div v-for="message of messagesWithFilter" v-bind:key="message.id">
                            <div class="message">
                                <div v-if="message.direction == 'received'" class="photo" style="background-image: url(https://icons.iconarchive.com/icons/custom-icon-design/pretty-office-8/64/User-blue-icon.png);">
                                    <div class="online"></div>
                                </div>
                                <div :class="message.direction == 'sent' ? 'sent' : 'response'">
                                    <div v-if="message.content_url">
                                        <div class="content-image" v-if="message.message_type == 'image'">
                                            <a target="_blank" :href="message.content_url">
                                                <img class="img-fluid img-thumbnail" :src="message.content_url" :alt="message.content_text" >
                                            </a>
                                            <extra-message-information :message="message"></extra-message-information>
                                        </div>
                                        <div class="content-video" v-if="message.message_type == 'video'">
                                            <video :src="message.content_url" preload="auto" controls="" style="width: 220px"></video>
                                            <extra-message-information :message="message"></extra-message-information>
                                        </div>
                                        <div class="content-video" v-if="message.message_type == 'audio'">
                                            <video :src="message.content_url" preload="auto" controls="" style="width: 220px"></video>
                                            <extra-message-information :message="message"></extra-message-information>
                                        </div>
                                        <div class="content-document" v-if="message.message_type == 'document'">
                                            <div>
                                                <p class="text">
                                                    <a target="_blank" :href="message.content_url">
                                                        <i class="icon h4 mb-2">
                                                            <i class="bi bi-file-earmark-pdf text-secondary"></i>
                                                        </i>
                                                        Baixar Arquivo</a>
                                                    <extra-message-information :message="message"></extra-message-information>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p v-if="message.content_text" class="text">
                                            {{ message.content_text }}
                                            <span style="display: flex; align-items: center;">
                                                <extra-message-information :message="message"></extra-message-information>
                                                <span style="font-size: 0.8em; text-transform: lowercase;"> &nbsp;{{ message.sent_by }}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.enrolment_id">
                            <div class="scroll-end-of-messages new-icons">
                                <i class="bi bi-arrow-down-circle-fill" @click="updateScroll"></i>
                            </div>
                        </div>
                    </div>

                    <div class="footer-chat">
                        <div v-if="canSendMessage()">
                            <button class="btn-emojis new-icons" type="button" data-toggle="collapse" data-target="#collapseEmojis" aria-expanded="false" aria-controls="collapseEmojis">
                                😄
                            </button>
                        </div>
                        <div v-else>
                            <button class="btn-emojis new-icons" type="button" style="cursor: not-allowed;">
                                😄
                            </button>
                        </div>

                      <div style="min-height: 120px;">
                          <div class="collapse height" id="collapseEmojis">
                            <div class="card card-body" style="width: 280px;">
                                <div class="content-child-flex-wrap">
                                    <a @click="addEmoji('🙂')">🙂</a>
                                    <a @click="addEmoji('😁')">😁</a>
                                    <a @click="addEmoji('😄')">😄</a>
                                    <a @click="addEmoji('😅')">😅</a>
                                    <a @click="addEmoji('🤗')">🤗</a>
                                    <a @click="addEmoji('😉')">😉</a>
                                    <a @click="addEmoji('🤩')">🤩</a>
                                    <a @click="addEmoji('🌹')">🌹</a>
                                    <a @click="addEmoji('⭐')">⭐</a>
                                    <a @click="addEmoji('🌟')">🌟</a>
                                    <a @click="addEmoji('✨')">✨</a>
                                    <a @click="addEmoji('🎉')">🎉</a>
                                    <a @click="addEmoji('👍')">👍</a>
                                    <a @click="addEmoji('👏')">👏</a>
                                    <a @click="addEmoji('👋')">👋</a>
                                </div>
                            </div>
                          </div>
                        </div>

                        <input type="file" id="file_upload" @change="loadTextFromFile" style="display: none" />
                            <i v-on="canSendMessage() ? { click: clickUpload } : {}" class="new-icons" :style="canSendMessage() ? 'cursor: pointer' : 'cursor: not-allowed'">
                            <i class="bi bi-paperclip"></i>
                        </i>
                        <span> {{ this.file.name }}</span>

                        <input id="txtInputMessageId" v-model="this.txtInputMessage" @keyup.enter="sendMessage" type="text" class="write-message" placeholder="Enviar mensagem..." :disabled="canSendMessage() ? false : true" />
                            <i class="bi bi-send-fill new-icons" v-on="canSendMessage() ? { click: sendMessage } : {}" :style="canSendMessage() ? 'cursor: pointer' : 'cursor: not-allowed'" style="color: cornflowerblue"></i>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    http,
    http_file
} from "../../http";
import lisvViewMessageTemplate from "../shared/message-template/lisvViewMessageTemplate.vue";
import MessageCards from "../shared/message-cards/MessageCards.vue";
import Loader from "../shared/painel/Loader.vue";
import messageAlertConfirmation from "../shared/messageAlertConfirmation.vue";
import ExtraMessageInformation from "../shared/extra-message-information/ExtraMessageInformation.vue";
import MessageTemplate from "../shared/message-template/MessageTemplate.vue";
import { tsExternalModuleReference } from "@babel/types";
import { useCookies } from "vue3-cookies";

export default {
    components: {
        "message-cards": MessageCards,
        "list-view-template": lisvViewMessageTemplate,
        "extra-message-information": ExtraMessageInformation,
        "message-template": MessageTemplate,
        Loader,
        "alerts-default": messageAlertConfirmation,
    },

    data() {
        return {
            local_type_confimation: null,
            message_alert: null, 
            type_alert: null, 
            name_first_button: null, 
            name_second_button: null,
            view_status: null,
            dismissSecs: 5,
            dismissCountDown: false,
            showDismissibleAlert_lesson_progress: false,
            dismissSecs_message_template: 5,
            dismissCountDown_message_template: false,
            showDismissibleAlert_message_template: false,
            title: "Todos os cursos",
            messages: [],
            enrolments: [],
            courses: [],
            tutors: [],
            enrolments_lesson: [],
            messages_templates: [],
            enrolment_id: null,
            talk_tutor: null,
            lesson_name: "teste lesson name",
            get_response_enrol: [],
            file: "",
            course_id: null,
            loader_options: true,
            back_to_lesson_progress: false,
            back_to_send_message_template: false,
            priority: null,
            template_id: null,
            name_user: null,
            email_user: null,
            tutor_id: null,
            user_id: 1,
            is_tutor: null,
            is_staff: null,
            is_admin: null,
            total_message: 0,
            heig_priority: 0,
            medium_priority: 0,
            enrolments_talk_tutor: 0,
            current_page: 1,
            enrolments_per_page: [],
            current_message_window_open: false,
            student_filter: null,
            name_student: null,
            student_id: null,
            catch_alert: {
                type: null,
                message: null,
            },
            exercise_external_token: null,
            txtInputMessage:"",
            txtInputFilterStudent:""
        }
    },

    beforeCreate() {},

    mounted() {       
        this.getInforUser()
        this.getAllCoursesFromApi()
        this.getAllTutorsFromApi()
        this.time = setInterval(this.checkFocusToShowMessages, 2500)
        this.checkScroll()
        this.countPriority()
    },

    computed: {
        messagesWithFilter() {
            return this.messages
        },

        enrolmentsWithFilter() {
            return this.enrolments
        },

        coursesWithFilter() {
            return this.courses
        },

        tutorsWithFilter() {
            return this.tutors
        },
    },

    methods: {
        closeEditStudentModal() {
            var count = 0
            
            if(count == 0){
                document.elementFromPoint(2, 2).click()
                count += 1
            }
        },

        statusUpdate: async function(type_confimation){
             if (type_confimation.type == 'delete_lesson_progress'){
                var response = await this.delete_lesson_progress()
                if (response != 'error'){                
                    this.view_status = type_confimation.view
                    this.name_first_button = null
                    this.name_second_button = null
                    this.type_alert = 'success'
                    this.message_alert = 'Apagado com sucesso'
                }else{
                    this.view_status = type_confimation.view
                    this.name_first_button = null
                    this.name_second_button = null
                    this.type_alert = 'danger'
                    this.message_alert = 'Erro ao excluir lesson progress'
                }
            }
        },

        cloaseAlert: function(cloase){
            this.view_status = cloase
        },

        openAlertLessonProgress: function () {
            this.message_alert = 'Você deseja realmente apagar o último Lesson Progress?'
            this.type_alert = 'warning'
            this.name_first_button = 'Cancelar'
            this.name_second_button = 'Deletar'
            this.view_status = true
            this.local_type_confimation = 'delete_lesson_progress'            
        },

        countPriorityMessages: function () {
            var payload = {
                course_id: this.course_id,
                tutor_id: this.tutor_id,
            }

            http.post("count-messages-by-course/", payload)
            .then((response) => {
                this.total_message = response.data["total_message"]
                this.heig_priority = response.data["heig_priority"]
                this.medium_priority = response.data["medium_priority"]
                this.enrolments_talk_tutor = response.data["talk_tutor"]
            })
            .then((messages) => (this.messages = messages))
        },

        checkFocusToShowMessages() {
            const elementWriteMessage = document.querySelector(".write-message")
            if (elementWriteMessage === document.activeElement) {
                this.getMessagesFromApi()
            }
        },

        getMessagesFromApi: function () {
            if (this.enrolment_id != null) {
                var url = 'enrollment/' + this.enrolment_id + '/list-messages'

                http.get(url)
                .then((res) => this.messages = res.data)                    
            }
        },

        optionTutor: function () {
            var payload = {
                enrollment_id: this.enrolment_id,
            }

            http.post("lesson_progress_by_enrollment", payload)
            .then((res) => this.enrolments_lesson = res.data)
            .catch(function (error) {})

            http.post("get_message_template_by_painel", payload)
            .then((res) => this.messages_templates = res.data)
            
            http.post("exercise_external_token/", payload)
            .then((res) => res["body"])
            .then((exercise_external_token) => {
                this.exercise_external_token = exercise_external_token
            })

            this.loader_options = false
        },

        delete_lesson_progress: async function () {
            var payload = {
                enrollment_id: this.enrolment_id,
            }
            
            await http.post("delete_last_lesson_progress", payload)
            .then(function (response) {})
            .catch(function (error) {})
            .finally(() => {
                this.optionTutor()
                this.back_to_lesson_progress = false
                return 'ok'
            })
        },

        init_send_message_template: function (template_id) {
            this.template_id = template_id
            var response_status = null
            var payload = {
                enrollment_id: this.enrolment_id,
                message_id: this.template_id,
            }
            
            http.post("send_message_template", payload)
            .then(function (response) {
                response_status = response.status
            })
            .catch(function (error) {
                this.message_alert = 'Erro ao enviar template'
                this.type_alert = 'danger'
                this.name_first_button = null
                this.name_second_button = null
                this.view_status = true
                this.local_type_confimation = 'alert'
            })
            .finally(() => {
                if (response_status == 200) {
                    this.optionTutor()
                    this.message_alert = 'Template Enviado com sucesso'
                    this.type_alert = 'success'
                    this.name_first_button = null
                    this.name_second_button = null
                    this.view_status = true
                    this.local_type_confimation = 'alert'
                }
            })
        },

        checkScroll() {
            var list
            var enrollment
            var course
            var by
            
            const divDiscussions = document.querySelector(".discussions")
            list -
            enrollment -
                by -
                course /
                divDiscussions.addEventListener("scroll", () => {
                    if (
                        divDiscussions.offsetHeight + divDiscussions.scrollTop >=
                        divDiscussions.scrollHeight
                    ) {
                        this.getNextPageOfEnrolments()
                    }
                })
        },

        getNextPageOfEnrolments() {
            if (this.course_id > 0) {
                var url = 'list-enrollments-by-course/'
                var payload = {
                    course_id: this.course_id,
                    current_page: this.current_page,
                    student_filter: this.student_filter,
                    tutor_id: this.tutor_id,                    
                }

                http.post(url, payload)
                .then((res) => res.data)
                .then((enrolments_per_page) => (this.enrolments_per_page = enrolments_per_page),)
                .then(() => {
                    for (var i = 0; i < this.enrolments_per_page.length; i++) {
                        this.enrolments.push(this.enrolments_per_page[i])
                    }
                    
                    this.enrolments_per_page = []
                    this.current_page++
                })
            }
        },

        getEnrolmentsFromApi: function () {
            if (this.course_id != null) {
                this.current_page = 1
                this.enrolments = []

                var tutor_id = null
                if (this.tutor_id != null) {
                    tutor_id = this.tutor_id
                }

                var url = 'list-enrollments-by-course/'
                var payload = {
                    course_id: this.course_id,
                    current_page: this.current_page,
                    student_filter: this.student_filter,
                    tutor_id: this.tutor_id,
                }

                http.post(url, payload)
                .then((res) => this.enrolments = res.data)
                .then((enrolments) => (this.enrolments = enrolments),);

                this.current_page++
            }
        },

        getAllCoursesFromApi: function () {
            http.get("list-all-courses")
            .then(
                response => {this.courses = response.data},
            )
        },

        getInforUser: function () {
            var token = null

            if (useCookies().cookies.isKey("user_session")) {
                token = useCookies().cookies.get("user_session")
            }

            if (token !== null && token !== '' && token !== undefined) {
                const payload = {
                    init_user: token,
                }

                http.post("info_user_for_token", payload)
                .then((response) => {
                    this.name_user = response.data["first_name"] + " " + response.data["last_name"]
                    this.ueser_id = response.data["user_id"]
                    this.email_user = response.data["email"]
                    this.is_admin = response.data["is_admin"]
                    this.is_staff = response.data["is_staff"]
                    this.is_tutor = response.data["is_tutor"]
                })
                .catch(function (error) {})
            }
        },

        getAllTutorsFromApi: function () {
            var payload = {
                course_id: this.course_id,
            }
            
            http.post("list-all-tutors/", payload)
            .then((res) => {this.tutors =  res.data})
            .catch(function (error) {})
        },

        clickEnrolment: function (enrolment_id, message_window_open) {
            this.messages = null
            this.enrolment_id = enrolment_id
            this.current_message_window_open = message_window_open
            this.student_phone_number = this.enrolments.find((enrolment) => enrolment.id === this.enrolment_id).student_phone_number
            this.sender_phone_number = this.enrolments.find((enrolment) => enrolment.id === this.enrolment_id).sender_phone_number
            this.talk_tutor = this.enrolments.find((enrolment) => enrolment.id === this.enrolment_id).talk_to_tutor
            this.name_student = this.enrolments.find((enrolment) => enrolment.id === this.enrolment_id).student_name
            this.student_id = this.enrolments.find((enrolment) => enrolment.id === this.enrolment_id).student_id
            this.getMessagesFromApi()
        },

        handleCheckboxChange: function () {
            var talkTutor = document.getElementById("talktutor").checked
            let payload = {
                enrolment: this.enrolment_id,
                talk: talkTutor,
            }

            http.post("update_talk_to_tutor", payload)
            .then(function (response) {})
            .catch(function (error) {})
        },

        handleChange(e) {
            this.course_id = e.target.value
            this.getEnrolmentsFromApi()
            this.getAllTutorsFromApi()
            this.countPriorityMessages()
        },

        handleChangeTutor(e) {
            this.tutor_id = e.target.value
            this.getEnrolmentsFromApi()
            this.countPriorityMessages()
        },

        getEnrolmentId: function () {
            return this.enrolment_id
        },

        addMessage() {
            if (this.txtInputMessage != "") {
                var payload = {
                    sender_phone_number: this.sender_phone_number,
                    student_phone_number: this.student_phone_number,
                    course_id: this.course_id,
                    message_type: "text",
                    content_text: "*" + this.name_user + ":* " + this.txtInputMessage,
                    media_url: null,
                }

                http.post("send-message-through-the-panel/", payload)
                .then((response) => this.postId = response.data, this.txtInputMessage = "")
                .finally(() => {
                    this.txtInputMessage = ''
                    this.updateScroll()
                })
                    
                this.txtInputMessage = ''
                this.updateScroll()
            }
        },

        addEmoji(emoji) {
            const textarea = document.getElementById("txtInputMessageId")
            textarea.value += emoji
            this.txtInputMessage + textarea.value
        },

        sendAttachment() {
            let formData = new FormData()
            formData.append("file", this.file)
            formData.append("sender_phone_number", this.sender_phone_number)
            formData.append("student_phone_number", this.student_phone_number)
            formData.append("course_id", this.course_id)
            formData.append("content_text", this.txtInputMessage)
            http_file.post("send-attachment/", formData).then((res) => res.data)
            this.txtInputMessage = ''
            this.file = ''
        },

        loadTextFromFile(ev) {
            const loadFile = ev.target.files[0]
            const reader = new FileReader()
            reader.onload = (e) => this.$emit("load", e.target.result)
            reader.readAsText(loadFile)
            this.file = loadFile
        },

        clickUpload() {
            document.getElementById("file_upload").click()
        },

        sendMessage() {
            if (this.file) {
                this.sendAttachment()
            } else {
                this.addMessage()
            }
        },

        canSendMessage() {
            if (this.current_message_window_open == true && this.enrolment_id != null) {
                return true
            } else {
                return false
            }
        },

        changePriorityOfAllMessages() {
            var payload = {
                enrollment_id: this.enrolment_id,
            }

            http.put("change-priority-of-all-messages/", payload)
            .then((response) => response.data)

            var enrolment = this.enrolments.find(
                (enrolment) => enrolment.id === this.enrolment_id
            )

            enrolment.count_high_priority = 0
        },

        updateScroll() {
            var element = document.getElementById("messageChat")
            element.scrollTop = element.scrollHeight
        },

        filterStudent() {
            if (this.course_id != null) {

                if (this.txtInputFilterStudent != '') {
                    this.student_filter = this.txtInputFilterStudent
                }
                this.getEnrolmentsFromApi()

                this.txtInputFilterStudent = ''
                this.student_filter = null
            }
        },

        editStudent: function () {
            var response_status = null
            var payload = {
                student_id: this.student_id,
                name_student: this.name_student,
                erollment_id: this.enrolment_id,
            }

            http.post("edit_student/", payload)
            .then(function (response) {
                response_status = response.status
            })
            .catch(function (error) {
                this.message_alert = 'Nome alterado com Sucesso !!'
                this.type_alert = 'success'
                this.name_first_button = null
                this.name_second_button = null
                this.view_status = true
                this.local_type_confimation = 'alert'
                
                setTimeout(() => {
                    this.closeEditStudentModal()
                    this.catch_alert.type = null
                }, 1000)
            })
            .finally(() => {
                if (response_status == 200) {
                    this.message_alert = 'Nome alterado com Sucesso !!'
                    this.type_alert = 'success'
                    this.name_first_button = null
                    this.name_second_button = null
                    this.view_status = true
                    this.local_type_confimation = 'alert'
                    
                    setTimeout(() => {                            
                        this.closeEditStudentModal()
                        this.catch_alert.type = null
                    }, 1000)
                }
            });
        },

        ResendCertificate: function () {
            var response_status = null
            var payload = {
                enrollment_id: this.enrolment_id,
            }

            this.catch_alert.type = 'warning_resend'
            this.catch_alert.message = 'Certificado esta sendo enviado!'
            
            http.post("resend_certificate/", payload)
            .then(function (response) {
                response_status = response.status
            })
            .catch((error) => {
                this.catch_alert.type = 'error_resend'
                this.catch_alert.message = 'Certificado não reenviado!'
                this.message_alert = 'Erro no envio'
                this.name_first_button = null
                this.name_second_button = null
                this.type_alert = 'danger'
                this.view_status = true
                this.local_type_confimation = 'alert'
            })
            .finally(() => {
                if (response_status == 200) {
                    this.catch_alert.type = 'success_resend'
                    this.catch_alert.message = 'Certificado reenviado com sucesso!'
                    this.message_alert = 'Certificado encaminhado com sucesso'
                    this.type_alert = 'success'
                    this.name_first_button = null
                    this.name_second_button = null
                    this.view_status = true
                    this.local_type_confimation = 'alert'
                }
            })
        },

        dataMessageTemplate: function () {
            return {
                title: 'Cadastrar novo Message Template'
            }
        },

        countPriority: function(){
            http.post("count_messages_priority/",)
            .then((response) => {
                this.total_message = response.data["total_message"]
                this.heig_priority = response.data["heig_priority"]
                this.medium_priority = response.data["medium_priority"]
                this.enrolments_talk_tutor = response.data["talk_tutor"]
            })
            .then((messages) => (this.messages = messages))
        }
    },

    destroyed() {
        clearInterval(this.time)
    },
};
</script>

<style>
html {
    overflow-y: auto;
}

.back-message {
    position: fixed;
}

.centralizado {
    text-align: center;
}

.filter {
    display: block;
    width: 100%;
}

.form-select {
    margin-top: 10px;
}

.container-chat {
    padding: 0;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    height: 480px;
}

.discussions {
    width: 35%;
    height: 480px;
    /* box-shadow: 0px 8px 10px rgba(0,0,0,0.20); */
    overflow: hidden;
    /* background-color: #87a3ec; */
    display: inline-block;
    overflow-y: scroll;
    padding-right: 0px;
}

.discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #c7d9e3;
    border-bottom: solid 1px #a7a6a6;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.discussions .search {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0e0e0;
}

.discussions .search .searchbar {
    height: 40px;
    background-color: #fff;
    width: 90%;
    padding: 26px 20px;
    border-radius: 50px;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.discussions .search .searchbar input {
    /* margin-left: 15px; */
    height: 38px;
    width: 100%;
    padding: 0% 5%;
    border: none;
    outline: none !important;
    font-family: "Montserrat", sans-serif;
    margin-right: 5px;
}

.discussions .search .searchbar *::-webkit-input-placeholder {
    color: #e0e0e0;
}

.discussions .search .searchbar input *:-moz-placeholder {
    color: #e0e0e0;
}

.discussions .search .searchbar input *::-moz-placeholder {
    color: #e0e0e0;
}

.discussions .search .searchbar input *:-ms-input-placeholder {
    color: #e0e0e0;
}

.discussions .message-active {
    width: 98.5%;
    height: 90px;
    background-color: #fff;
    border-bottom: solid 1px #b7b7b7;
}

.discussions .discussion .photo {
    margin-left: 20px;
    display: block;
    /*width: 45px;
  height: 45px;*/
    width: 100px;
    height: 40px;
    background: #e6e7ed;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8bc34a;
    border-radius: 13px;
    border: 3px solid #efefef;
}

.desc-contact {
    height: 43px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 11pt;
    color: #515151;
}

.discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 9pt;
    color: #515151;
}

.timer {
    margin-left: 15%;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #bbb;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
}

.chat {
    /* width: calc(65% - 85px); */
    width: 65%;
}

.chat .messages-chat {
    padding: 25px 35px 25px 10px;
    height: 400px;
    overflow-y: scroll;
    /* display: flex;
  flex-direction: column-reverse; */
}

.chat .messages-chat .message {
    display: -webkit-box;
    align-items: center;
    margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #e6e7ed;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.response {
    max-width: 500px;
}

.response .text {
    margin: 0 15px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
}

.sent .text {
    background-color: #e3effd !important;
    margin: 0 35px;
    padding: 15px;
    border-radius: 12px;
}

.content-image {
    margin: 0 35px 10px 35px;
}

.content-video {
    margin: 0 35px 10px 35px;
}

.content-document {
    margin-bottom: 10px;
}

.text-only {
    margin-left: 45px;
}

.time {
    font-size: 10px;
    color: lightgrey;
    margin-bottom: 10px;
    margin-left: 85px;
}

.sent-time {
    float: right;
    margin-right: 40px !important;
}

.sent {
    float: right;
    margin-right: 0px !important;
    margin-left: auto;
    max-width: 500px;
    /* flexbox alignment rule */
}

.footer-chat {
    /* width: calc(65% - 66px); */
    height: 80px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    /* position:absolute; */
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #eee;
}

.chat .footer-chat .icon {
    margin: 0 20px;
    cursor: pointer;
    /* margin-left: 30px;
  color: #c0c0c0;
  font-size: 14pt; */
}

/* .chat .footer-chat .send {
  color: #fff;
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
} */

.chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 13pt;
    color: #515151;
}

.chat .footer-chat .right {
    position: absolute;
    right: 40px;
}

.write-message {
    border: none !important;
    outline: none !important;
    width: 90%;
    height: 40px;
    /*margin-left: 20px;
  margin-right: 20px;*/
    padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
}

.footer-chat input *:-moz-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
}

.footer-chat input *::-moz-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
    margin-left: 5px;
}

.footer-chat input *:-ms-input-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
}

#chat-bar {
    display: inline;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    padding: 1% 0%;
    text-align: center;
    position: relative;
    background: #c9cacd;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
}

/* .clickable {
  cursor: pointer;
} */

.table {
    max-height: 5rem;
}

#h6options {
    margin-top: 2rem;
}

#btnLssonProgress {
    max-width: 16rem;
    max-height: 3rem;
    justify-content: flex-end;
    align-items: baseline;
    align-content: flex-end;
    margin-bottom: 1rem;
}

#chat-bar .row .col {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.center-items-table {
    display: flex;
    justify-content: center;
    align-items: center;
}

.count-high-priority {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    background-color: #dc3545;
    padding-top: 3px;
    /* margin-top: -60px; */
    text-align: center;
    font-size: 14px;
}

.priority-number {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    align-content: center;
}

@media screen and (min-width: 1600px){
    .scroll-end-of-messages {
        bottom: 0;
        right: 0;
        margin-bottom: 5%;
        margin-right: 2%;
        position: absolute;
        float: right;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
    .scroll-end-of-messages {
        bottom: 0;
        right: 0;
        margin-bottom: 7%;
        margin-right: 4%;
        position: absolute;
        float: right;
    }
}

@media screen and (min-width: 1028px) and (max-width: 1399px){
    .scroll-end-of-messages {
        bottom: 0;
        right: 0;
        margin-bottom: 10%;
        margin-right: 4%;
        position: absolute;
        float: right;
    }
}

@media screen and (max-width: 1027px){
    .scroll-end-of-messages {
        bottom: 0;
        right: 0;
        margin-bottom: 15%;
        margin-right: 4%;
        position: absolute;
        float: right;
    }
}

.icon-filter-student {
    margin-top: 5px;
}

/* Início inseridos pelo Gu */
.content-father-flex {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-between;
}

.content-child-flex {
    gap: 60px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;    
    justify-content: center;
}

.content-child-flex-wrap {
    gap: 20px;
    paddin: 5px;
    display: flex;    
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;    
    justify-content: center;
}

.content-child-flex-wrap a {
    cursor: pointer;
    font-size: 20px;
}

#collapseEmojis {
    top: 0;
    left: 0;
    margin-top: -55%;
    margin-left: -20%;
    position: relative;
}

.btn-emojis {
    border: none;
    outline: none;
    background-color: transparent;
}

.new-icons {
    display: flex;
    padding: 10px;
    cursor: pointer;
    font-size: 25px;
    align-items: center;
    justify-content: center;
}

#txtInputMessageId {
    border: none;
    border-bottom: 2px solid #a1a1a1 !important;
}

.modal-opcoes-tutores {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-opcoes-tutores .modal-content {
    max-width: 80%;
    max-height: 90%;
}

.header-modal-options {
    display: flex;
    align-items: center !important;
}

.table-max-height-scroll {
    height: 25vmax;
    overflow-y: auto;
}
/* Fim inseridos pelo Gu */
</style>