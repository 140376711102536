<template>
  <div class="container-fluid">
    <div style="margin-bottom: 15px">
      <router-link to="metodologia">
        <button class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </router-link>
    </div>

    <div class="card shadow mb-4 mt-1">
      <div class="card-header py-3 d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start align-items-center gap-3">
          <div>                
            <h5 class="m-0 font-weight-bold text-primary">Tabela de Cursos</h5>
          </div>
        </div>

        <div id="filters" class="col-4 d-flex justify-content-end align-items-center gap-3">
            <input type="text" class="form-control" style="height: 3rem;" v-model="search_filter" placeholder="Pesquisa de curso pelo token ou nome" @keyup.enter="searchByTokenOrName">
            <button class="btn btn-info d-flex align-items-center justify-content-center fw-bold" style="height: 2.5rem; width: 2.5rem; border-radius: 2.5rem;" @click="searchByTokenOrName"><i class="bi bi-search"></i></button>
          </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Company</th>
                <th scope="col">Token</th>
                <th scope="col">Solução</th>
                <th class="text-center" scope="col">Inscrições paradas</th>
                <th class="text-center" scope="col">Ativo</th>
                <th scope="col">Creado em</th>
                <th scope="col">Opções</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading !== true && loading_pagination != true && this.list_courses" v-for="course in this.list_courses" :key="course.course_id">
                <td>{{ course.course_name }}</td>
                <td>{{ course.company_name }}</td>
                <td>{{ course.course_external_exercise_id }}</td>
                <td>{{ course.flow_execution === 'original' ? 'Alya' : course.flow_execution === 'chatbots' ? 'Lumy' : '' }}</td>
                <td class="text-center">
                  <i v-if="course.stop_subscriptions" class="fas fa-check text-success"></i>
                  <i v-else class="fas fa-times text-danger"></i>
                </td>
                <td class="text-center">
                  <i v-if="course.is_active" class="fas fa-check text-success"></i>
                  <i v-else class="fas fa-times text-danger"></i>
                </td>
                <td>{{ formatDate(course.created) }}</td>
                <td>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Opções
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" :href="'/?#/course/' + course.course_id">Ver Detalhes</a>
                      <a class="dropdown-item" :href="'https://lys.academy/certificadoBot/PDF.php?token_course=' + course.course_external_exercise_id + '&nome=Aluno teste certificado&data_inicio=2024-09-22&data_fim=2024-10-01'" target="_blank">Exemplo certificado</a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-else>
                <td v-if="loading !== true && loading_pagination != true" colspan="8" class="text-center">Nenhum curso encontrado.</td>
                <td v-else colspan="8" class="text-center">
                  <Loader />
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="loading !== true && loading_pagination !== true && this.list_courses" class="pagination"> <!-- v-if para apenas quando o list_courses for carregado -->
            <button v-if="currentPage>1" class="btn-pagination" @click="prevPage" :disabled="currentPage === 1"><i class="bi bi-arrow-left-circle" style="
              font-size: 1.3rem;" ></i></button>
            <span>&nbsp; {{ currentPage }} de {{ totalPages }} &nbsp;</span>
            <button v-if="currentPage < totalPages" class="btn-pagination" @click="nextPage" :disabled="currentPage === totalPages"><i class="bi bi-arrow-right-circle" style="
              font-size: 1.3rem;"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { http } from "../../http";
import Loader from "../shared/painel/Loader.vue";

export default {
  name: "CourseList",
  components: {
      Loader,
  },

  data() {
    return {
      loading: true,
      loading_pagination: false,
      token_course: '',
      list_courses: [],
      currentPage: 1,
      totalPages: 1,
      search_filter: '',
    };
  },

  mounted() {
    this.list_all_courses(this.currentPage);
  },

  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
      return new Date(dateString).toLocaleDateString('pt-BR', options);
    },

    list_all_courses(page) {
      const payload = {
        current_page: parseInt(page),
        search_filter: this.search_filter.toString()
      };

      http.post("painel/atend_comercial/list_courses_tutor/", payload)
      .then((res) => {
        this.list_courses = res.data.results;
        this.totalPages = res.data.total_pages;
      })
      .catch((error) => {
        console.error('Erro ao listar cursos:', error);
      })
      .finally(() => {          
        setTimeout(() => {
            this.loading = false;
            this.loading_pagination = false;
        }, 1000);
      });      
    },    

    searchByTokenOrName() {
      if (this.search_filter == '') {
          this.search_filter = '';
      } else {
          this.search_filter = this.search_filter;
      }

      this.loading_pagination = true;


      this.currentPage = 1;
      this.list_all_courses(this.currentPage);
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loading_pagination = true;
        this.list_all_courses(this.currentPage);
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loading_pagination = true;
        this.list_all_courses(this.currentPage);
      }
    },
  },
};
</script>    

<style>
  .btn-pagination {
    margin: none  ;
    border: none;
    background-color: transparent;
    color: black;
    font-weight: bold;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
</style>